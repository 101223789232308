import React from "react";
import "./Verticals.css";
import one from "../../../../data/image/verticals/1.svg";
import two from "../../../../data/image/verticals/2.svg";
import three from "../../../../data/image/verticals/3.svg";
const verticals = () => {
  return (
    <div className="verticals_main">
      <div className="header_verticals">
        <h1>OUR FOCUS VERTICALS</h1>
      </div>

      <div className="retail">
        <div className="container_card">
          <div className="text_container">
            <h3>Retail</h3>

            <p>
              Accelerate your retail transformation With years of experience in
              the multi-location retail space, a range of innovative services
              and a team of true retail experts, we are uniquely able to help
              you leverage the power of your network to deliver a
              differentiating customer experience and bring about transformative
              change. For the last decade retail had to adapt technologies to
              stay in front of the dynamic changes in the industry. We assist
              transforming companies with cybersecurity to comply with PCI DSS,
              IOT, and many other aspects of retail.
            </p>
          </div>
          <img src={one} className="retail_img" alt="retail_img" />
        </div>
      </div>

      <div className="healthcare">
        <div className="container_card_healthcare">
          <div className="text_container_healthcare">
            <h3>Healthcare</h3>

            <p>
              The Healthcare Industry is one of the largest and is expected to
              continue growing well into the future guided by several drivers.
              Key among them will be technological innovation, integration of
              medicine and healthcare, smart healthcare date management and a
              patient centered restructuring. This will call for massive
              investments in terms of cognitive computing, electronic patient
              records, interoperability, dig data, etc. We will assist you with
              your digital transformation with innovative and cost-effective
              ways to deliver patient-centered technology enabled health care
              and with data protection to comply with HIPPA and PCI DSS, and
              customer’s experience.
            </p>
          </div>
          <img src={two} className="retail_img" alt="retail_img" />
        </div>
      </div>

      <div className="retail">
        <div className="container_card">
          <div className="text_container">
            <h3>Manufacturing and Distribution</h3>

            <p>
              Manufacturers and distributors increasingly rely on data,
              information, and technologies to run their operations. With
              competing priorities and limited resources, manufacturers need
              guidance, solutions, and training that is practical, actionable,
              cost effective, and ultimately helps them manage their IT, and
              cybersecurity as well as privacy risks. We assist manufacturing
              and distribution entities to stay ahead of the curb, with the
              latest trends and updates to assure data privacy, cybersecurity,
              and IOT integration are provided by the best in the segment.
            </p>
          </div>
          <img src={three} className="retail_img" alt="retail_img" />
        </div>
      </div>
    </div>
  );
};

export default verticals;
