import React from "react";
import "./OurProcess.css";

import ana from "../../../../data/image/ourprocess/1.jpeg";
import srt from "../../../../data/image/ourprocess/2.jpeg";
import per from "../../../../data/image/ourprocess/3.jpeg";
import imp from "../../../../data/image/ourprocess/10.webp";

const OurProcess = () => {
  return (
    <div className="ourprocess">
      <div className="header">
        <div className="container_ourprocess">
          <h1>OUR CONSULTING PROCESS</h1>
          <p>
            With over 20 years of IT experience, we understand the process
            required to commit to a change and adapt new solutions. We have been
            assisting organizations of different scales and structures to
            improve and modernize their IT strategies, and cybersecurity
            posture. This has allowed us to craft an effective approach to IT
            consulting that enables us to deliver maximum benefit in the
            shortest time.
          </p>
        </div>
      </div>
      <div className="info-box-parent">
        <div className="info-box-section">
          <div className="info-box-section-border" />
          <div className="info-box-section-content">
            <img
              src={ana}
              className="info-box-section-img"
              alt="Discover phase illustration"
            />
            <h4 className="info-box-section-title">DISCOVER</h4>
            <div className="info-box-section-desc">
              Our consulting advisors study your existing solutions and business
              current and future goals, and identify areas of concern.
            </div>
          </div>
        </div>
        <div className="info-box-section">
          <div className="info-box-section-border" />
          <div className="info-box-section-content">
            <img
              src={srt}
              className="info-box-section-img"
              alt="Strategy phase illustration"
            />
            <h4 className="info-box-section-title">STRATEGY</h4>
            <div className="info-box-section-desc">
              Our professionals design a roadmap and strategy that will help
              your business leverage the latest technologies to align with the
              company future vision.
            </div>
          </div>
        </div>
        <div className="info-box-section">
          <div className="info-box-section-border" />
          <div className="info-box-section-content">
            <img
              src={per}
              className="info-box-section-img"
              alt="Delivery phase illustration"
            />
            <h4 className="info-box-section-title">DELIVERY</h4>
            <div className="info-box-section-desc">
              Impactful consulting services rely on collaboration between a
              company and its providers. Our experts will closely analyze
              delivery, and track their performance and assure best-in-class
              delivery.
            </div>
          </div>
        </div>
        <div className="info-box-section">
          <div className="info-box-section-border" />
          <div className="info-box-section-content">
            <img
              src={imp}
              className="info-box-section-img"
              alt="Optimize and improvement phase illustration"
            />
            <h4 className="info-box-section-title">OPTIMIZE AND IMPROVEMENT</h4>
            <div className="info-box-section-desc">
              After completing the set goals, we will revisit to enhance and
              improve the results, and set new goals as needed.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProcess;
