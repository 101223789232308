import React, { useRef } from "react";
import "./NavBar.css";
import logo from "../../data/image/logo/1.jpeg";
import { Icon } from "@iconify/react";

import { Link } from "react-router-dom";

const NavBar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <header>
      <Link to="/">
        <img className="logo" alt="logo" src={logo} />
      </Link>
      <nav ref={navRef}>
        <a href="#about"> About Us</a>

        <a href="#ourexpertise">Services</a>
        <a href="#verticals">Verticals</a>
        <a href="#ourprocess">Technologies</a>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <Icon icon="bi:x-lg" />
        </button>
        <button
          onClick={() => (window.location.href = "#contact_ca")}
          className="button-60"
        >
          Contact Us
        </button>
      </nav>

      <button className="nav-btn bu" onClick={showNavbar}>
        <Icon className="nav-btn nav-close-btn" icon="ci:hamburger" />
      </button>
    </header>
  );
};

export default NavBar;
