import "./App.css";
import NavBar from "./components/navbar/NavBar";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" caseSensitive={false} element={<Home />} />
        </Routes>
      </Router>
      <div className="copyright">
        <h5>
          Copyright © {currentYear} UPTIMIZE CONSULTING LLC. All Rights
          Reserved.
        </h5>
      </div>
      {/* <Router>
        <Routes>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={AboutUs} />
        </Routes>
      </Router> */}
    </>
  );
}

export default App;
